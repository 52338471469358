import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { Stage } from '../model/book-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookReaderApiService {

  constructor(      
    private http: HttpClient
  ) { }

  getEbookContent(path: string, page: number, size: number): Observable<string> {
    return this.http.get<string>(`${environment.appEndpoint}/api/books/content/${path}&page=${page}&size=${size}`,
        {responseType : 'text' as 'json'}
    )
  }

  getEbookAnchors(path: string): Observable<Stage> {
    let productPath = path.split("?")[0]
    return this.http.get<Stage>(`${environment.appEndpoint}/api/products/${productPath}/metadata?source=repo`)
  }
  //check env
}
