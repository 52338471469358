<div class="workspace vh-100 bg-linear-radial">
  <top-bar class="accent" [ngClass]="getAccent()">
    <content class="mb-md-4">
      <button
        type="button"
        class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        [routerLink]="'../'"
      >
        <app-icon name="arrow-back"></app-icon>
        <span class="d-none d-lg-block ms-3"
          [translate]="'NAVIGATION.EBOOKS'"></span>
      </button>
      <div class="text-center">
        <h4 [translate]="'EBOOKS.TITLE'"></h4>
      </div>
      <button
        aria-controls="offcanvasEBookOptions"
        class="d-md-none  option-button border-0 bg-transparent px-0"
        data-bs-target="#offcanvasEBookOptions"
        data-bs-toggle="offcanvas"
        type="button"
      >
        <app-icon name="dots-options"></app-icon>
      </button>
    </content>
    <footer>
      <div class="d-none d-md-block">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="../../">
                {{ "EBOOKS.TITLE" | translate }}
              </a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="../../">
                {{ "EBOOKS.FILTER_LANGS.ALL" | translate }}
              </a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="goToEbooksListWithFilter(getLanguageFilter())">
                {{ getEbookLanguageTranslation() | translate }}
              </a>
            </li>
            <li class="breadcrumb-item" aria-current="page" routerLink="../">
              {{ getEbookName() }}
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "EBOOKS.READER" | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </footer>
  </top-bar>
    <div class="container reader" *ngIf="!isEbookLoaded || ebook?.path; else noEBook">
      <app-web-browser [ebookPath]="ebook?.path" ></app-web-browser>
    </div>
  <ng-template #noEBook>
    <div class="container">
      <span>{{ "EBOOKS.NO_EBOOK_ERROR" | translate }}</span>
    </div>
  </ng-template>
</div>

<div
  aria-labelledby="offcanvasEBookOptionsLabel"
  class="offcanvas offcanvas-menu offcanvas-end offcanvas-ebook-options" 
  id="offcanvasEBookOptions"
  tabindex="-1"
>
  <div class="offcanvas-body p-0 pt-4 pt-sm-0 d-flex">
    <div class="w-100">
      <div class="d-flex justify-content-end">
        <button
        type="button"
        class="btn btn-primary rounded-circle btn-circle text-reset p-0"
        data-bs-dismiss="offcanvas"
        aria-label="Close" 
      >
        <app-icon name="close" class="icon-white"></app-icon>
      </button>
      </div>
      <div class="mt-3 d-flex flex-row align-items-center">
        <span class="fs-6">{{ "EBOOKS.TEXT_SIZE" | translate }}</span>
        <div class="rounded-pill p-2 change-text-size-button ms-2 shadow">
          <app-icon 
                class="mx-1"
                name="text-decrease"
                (click)="changeTextSize(-1)"
            ></app-icon>
        </div>
        <div class="rounded-pill p-2 change-text-size-button ms-2 shadow">
            <app-icon 
                class="mx-1"
                name="text-increase"
                (click)="changeTextSize(1)"
            ></app-icon>
          </div>
      </div>
    </div>
  </div>
</div>