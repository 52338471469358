<div class="container-fluid bg-dark">
  <nav class="navbar navbar-expand-sm m-0 p-0">
    <!-- profile nav on top right  -->
    <div class="nav-container">
      <!-- face button -->
      <div class="dropdown">
        <button
          type="button"
          class="btn btn-primary btn-circle btn-sm d-none d-md-block p-0"
          id="dropdownMenu2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          [class]="profilePhoto ? 'avatar' : ''"
          [ngStyle]="getAvatar()"
        >
          <app-icon *ngIf="!profilePhoto" name="smile"></app-icon>
        </button>
        <ul
          class="dropdown-menu dropdown-profile mt-3"
          aria-labelledby="dropdownMenu2"
        >
          <li>
            <a
              class="dropdown-item text-muted"
              routerLink="./profile/profile-form"
            >
              <app-icon name="profile"></app-icon>
              {{ "NAVIGATION.EDIT_PROFILE" | translate }}
            </a>
          </li>
          <!-- <li>
                        <a class="dropdown-item text-muted">
                            <app-icon name="sync"></app-icon>
                            Switch The Profile
                        </a>
                    </li> -->
          <li>
            <hr class="my-2" />
          </li>
          <li>
            <a
              class="dropdown-item text-muted"
              routerLink="./"
              (click)="onLogout()"
            >
              <app-icon name="log-out"></app-icon>
              {{ "BUTTON.LOG_OUT" | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="d-none d-xl-flex flex-column mb-2 pe-4 border-end border-primary">
        <span class="small">{{ getTeacherName() }}</span>
        <span class="xsmall text-muted"> {{ getTeacherRole() }}</span>
      </div>
      <!-- notifications button  -->
      <div class="dropdown">
        <button
          type="button"
          class="btn btn-primary btn-circle d-none d-md-block ms-3"
          id="notifications"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <app-icon name="notification-white"></app-icon>
          <!-- <span class="position-absolute bottom-left rounded-circle bg-danger p-2">
                        <span class="visually-hidden">unread messages</span>
                    </span> -->
        </button>
        <ul
          class="dropdown-menu dropdown-notifications mt-3"
          aria-labelledby="notifications"
        >
          <li>
            <p class="text-muted small mb-0 text-center p-2">
              {{ "NOTIFICATIONS.NO_NOTIFICATIONS" | translate }}
            </p>
          </li>
        </ul>
      </div>
      <button
        *ngIf="menuType === 'defaultHamburger'"
        type="button"
        class="btn btn-primary btn-circle d-md-none px-0"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBackdrop"
        aria-controls="offcanvasWithBackdrop"
      >
        <app-icon name="menu"></app-icon>
      </button>
    </div>

    <!-- desktop navigation panel on left -->
    <div class="navbar-panel d-none d-md-block">
      <teacher-main-nav
        [theme]="theme"
        [activeSegments]="activeSegments"
        class="h-100"
      ></teacher-main-nav>
    </div>
  </nav>
  <!-- offcanvas menu on mobile -->
  <div
    class="offcanvas offcanvas-menu offcanvas-end"
    tabindex="-1"
    id="offcanvasWithBackdrop"
    aria-labelledby="offcanvasWithBackdropLabel"
  >
    <div class="offcanvas-body p-0 pt-4 pt-sm-0 d-flex">
      <teacher-main-nav
        [theme]="theme"
        [isOffcanvas]="true"
        [activeSegments]="activeSegments"
        [profilePhoto]="profilePhoto"
        class="w-100"
      ></teacher-main-nav>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
