<spinner class="spinner position-static w-100"></spinner>

<div class="book-container mb-4 w-100 h-100 shadow"
  [innerHTML]="ebookContent"
  [ngStyle]="getZoom()" 
></div>
   
<div class="control-bar shadow">
      <div class="nav-item my-2">
        <app-icon
          (click)="moveToPreviousPage()"
          class="md-icon"
          name="arrow-back"
        ></app-icon>
      </div>
      <div class="nav-item my-2 d-block d-md-block">
        <app-icon
          (click)="jumpToPageModal.show()"
          class="md-icon"
          name="go-to-page"
        ></app-icon>
      </div>
      <div class="nav-item my-2 d-none d-md-block position-relative">
        <app-icon
          (click)="showBookSizeSettings()"
          class="md-icon"
          name="font-size"
        ></app-icon>
        <ng-container *ngIf="isBookSizeSettingsShown" class="position-absolute ">
            <div class="font-size-menu rounded-pill shadow px-3 py-2 d-flex flex-row justify-content-between">
            <app-icon 
              class="me-3"
              name="text-decrease"
              (click)="changeTextSize(-1)"
            ></app-icon>
            <app-icon 
              name="text-increase"
              (click)="changeTextSize(1)"
            ></app-icon>
        </div>
        </ng-container>
      </div>
      <div class="nav-item my-2">
        <app-icon
          (click)="moveToNextPage()"
          class="md-icon"
          id="buttonNext"
          name="arrow-next"
        ></app-icon>
      </div>
</div>

<app-modal #jumpToPageModal >
  <form #form="ngForm" class="jump-to-page modal-content" (submit)="jumpToPage()">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn-close"
        (click)="jumpToPageModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div>
      <span class="mb-2 me-2">{{ "EBOOKS.JUMP_TO_PAGE" | translate }}</span>
      <label>[{{firstPage}} - {{lastPage}}]</label>
      <input 
        [(ngModel)]="pageSelection" 
        [ngModelOptions]="{standalone: true}" 
        class="page-input form-control my-2"
        type="number"
        required
        #pageSelectionInput="ngModel"
        [min]="firstPage"
        [max]="lastPage"
        />
      <div class="text-danger" *ngIf="pageSelectionInput.invalid && pageSelectionInput.touched">
        {{ "EBOOKS.RANGE_ERROR" | translate}} {{firstPage}} - {{lastPage}}.
      </div>
    </div>
    <div class="d-flex justify-content-end ">
      <button class="btn btn-primary rounded-pill" [disabled]="pageSelectionInput.invalid" (click)="jumpToPage()">
        {{ "EBOOKS.GO_TO_PAGE" | translate }}
      </button>
    </div>
  </form>
</app-modal>