import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ebook, OwnershipLocalState } from '../../model/book-model';
import { ActivatedRoute, Router } from '@angular/router';
import { LangCode } from 'src/app/utils/text-compare';
import { OffcanvasMenuService } from 'src/app/services/offcanvas-menu.service';
import { switchMap, tap } from 'rxjs';
import { BookReaderService } from '../../services/book-reader.service';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { CourseFilter } from 'src/app/model/CourseFilters';
import { SpinnerService } from 'src/app/utils/services/spinner.service';

@Component({
  selector: 'app-book-reader',
  templateUrl: './book-reader.component.html',
  styleUrls: ['./book-reader.component.scss'],
  standalone: false
})
export class BookReaderComponent implements OnInit, OnDestroy {

  ebook: Ebook
  ebookId: number
  isEbookLoaded: boolean

  constructor(
    private offcanvasMenuService: OffcanvasMenuService,
    private bookReaderService: BookReaderService,
    private router: Router,
    private route: ActivatedRoute,
    private apiProvider: NativeServiceApiProvider,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.offcanvasMenuService.setMenu('exerciseOptions')
    this.route.paramMap.pipe(
      tap(params => {
        this.ebookId = +params.get("ebookId");
      }),
      switchMap(_ => this.getEBook()))
      .subscribe()
  }

  ngOnDestroy(): void {
    this.offcanvasMenuService.setMenu("defaultHamburger")
  }

  getAccent() {
    return `stg-${this.ebook?.name.slice(this.ebook.name.length - 2).trim()}`
  }

  getEbookName() {
    return this.ebook?.name
  }

  changeTextSize(change: -1 | 1){
    this.bookReaderService.setSize(change)
  }

  getEBook(){
    return this.spinner.trace<OwnershipLocalState>(this.apiProvider.books().pipe(
      switchMap( api => api.getProductById(this.ebookId)),
      tap(ownershipLS => {
            if(ownershipLS?.ownership?.eBook) this.ebook = ownershipLS.ownership.eBook
            this.isEbookLoaded = true
      })
    ))
  }

  getEbookCategory() {
    if(this.ebook?.categories[0] === "English") return this.ebook?.categories[1]
    else return this.ebook?.categories[0]
  }

  getLanguageFilter(){
      let lang = this.getEbookCategory()
      return CourseFilter.filters.find(filter => filter.category == lang).filter
    }

  goToEbooksListWithFilter(language: string) {
    this.router.navigate(
      ['../../'],
      {
        relativeTo: this.route,
        queryParams: { language: `${language}`},
        queryParamsHandling: 'merge'
      }
    )
  }

  getEbookLanguageTranslation() {
    return this.ebook?.categories ? 'EBOOKS.FILTER_LANGS.' + LangCode[this.ebook.categories[0]] : ''
  }
}
