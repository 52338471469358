{
  "name": "student-area",
  "version": "2.6.4-s",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.6",
    "@angular/cdk": "^19.1.4",
    "@angular/common": "^19.1.6",
    "@angular/compiler": "^19.1.6",
    "@angular/core": "^19.1.6",
    "@angular/forms": "^19.1.6",
    "@angular/material": "^19.1.4",
    "@angular/platform-browser": "^19.1.6",
    "@angular/platform-browser-dynamic": "^19.1.6",
    "@angular/router": "^19.1.6",
    "@fortawesome/fontawesome-free": "~5.7.2",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@popperjs/core": "^2.11.4",
    "@sentry/angular": "^9.0.1",
    "@sentry/tracing": "^7.120.3",
    "@stomp/stompjs": "^7.0.0",
    "bootstrap": "^5.1.3",
    "rxjs": "~7.5.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.7",
    "@angular/cli": "^19.1.7",
    "@angular/compiler-cli": "^19.1.6",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^12.11.1",
    "jasmine-core": "~4.0.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "typescript": "5.5.4"
  }
}
