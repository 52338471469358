import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { RouterModule } from '@angular/router';
import { BookListComponent } from './pages/book-list/book-list.component';
import { BookDetailsComponent } from './pages/book-details/book-details.component';
import { BookListItemComponent } from './components/book-list-item/book-list-item.component';
import { BookReaderComponent } from './pages/book-reader/book-reader.component';
import { WebBrowserComponent } from './components/web-browser/web-browser.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BookListComponent,
    BookDetailsComponent,
    BookListItemComponent,
    BookReaderComponent,
    WebBrowserComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    BookListComponent,
    BookDetailsComponent,
    BookListItemComponent
  ]
})
export class BooksModule { }
