import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookReaderService {
  
  private zoomSize: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  ZOOM_LEVELS = [0.5, 0.8, 1.0, 1.2, 1.3, 1.5, 2.0]
  zoomValue: number = 2

  constructor() { 
    this.zoomSize.next(this.ZOOM_LEVELS[this.zoomValue])
  }

  setSize(size: -1 | 1){
    if(size === -1){
      if(this.zoomValue > 0)
        this.zoomSize.next(this.ZOOM_LEVELS[--this.zoomValue])
    }
    if(size === 1){
      if(this.zoomValue < this.ZOOM_LEVELS.length - 1)
        this.zoomSize.next(this.ZOOM_LEVELS[++this.zoomValue])
    }
  }

  getSize(): Observable<number>{
    return this.zoomSize.asObservable()
  }

}
