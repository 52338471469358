import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, switchMap, tap } from 'rxjs';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { BookReaderService } from '../../services/book-reader.service';
import { BookReaderApiService } from '../../services/book-reader-api.service';
import { Stage } from '../../model/book-model';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-web-browser',
  templateUrl: './web-browser.component.html',
  styleUrls: ['./web-browser.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class WebBrowserComponent implements OnInit, OnDestroy {
  ebookContent: SafeHtml
  _ebookPath: string

  @Input() 
  set ebookPath(value: string){
    if(value) {
      this._ebookPath = value
      this.getEbookAnchors().pipe(
          switchMap(_ => this.getEbookContent())
        ).subscribe()
    }
  }
  get ebookPath(): string {
    return this._ebookPath
  }
  pageNum: number
  pageSelection: number
  firstPage: number = Infinity
  lastPage: number = -Infinity
  stage: Stage

  @ViewChild('jumpToPageModal', { static: true })
  jumpToPageModal: ModalComponent;

  isBookSizeSettingsShown: boolean = false
  zoom: number

  sizeSubscription: Subscription

  constructor(
    private bookReaderApi: BookReaderApiService,
    private sanitizer: DomSanitizer,
    private bookReaderService: BookReaderService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sizeSubscription = this.bookReaderService.getSize().subscribe(
      size => this.zoom = size
    )
    this.route.queryParams.subscribe(params => {
      const pageNum = params['page']
      this.pageNum = pageNum ? +pageNum : 0
    })

  }

  ngOnDestroy(): void {
    if(this.sizeSubscription) this.sizeSubscription.unsubscribe()
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!event || !event.code) {
      return;
    }
    if (event.code === 'ArrowLeft' ) {
      this.moveToPreviousPage()
    }
    if (event.code === 'ArrowRight') {
      this.moveToNextPage()
    }
  }

  moveToNextPage(){
    if(this.pageNum != this.lastPage) this.pageNum += 1
    this.updatePageNumberInParams(this.pageNum)
    this.getEbookContent().subscribe()
  }

  moveToPreviousPage(){
    if(this.pageNum != this.firstPage) this.pageNum -= 1
    this.updatePageNumberInParams(this.pageNum)
    this.getEbookContent().subscribe()
  }

  getEbookAnchors(){
    return this.spinner.trace(this.bookReaderApi.getEbookAnchors(this._ebookPath).pipe(
      tap(response => {
        this.stage = response
        this.stage['content-items'].forEach(item => {
         if(item.anchors){
           this.firstPage = Math.min(this.firstPage, item.anchors.start)
           this.lastPage = Math.max(this.lastPage, item.anchors.end)
         }
        })
        if(this.pageNum === 0) {
          this.pageNum = this.firstPage
          this.updatePageNumberInParams(this.firstPage)
        }
      })
    ))
  }

  getEbookContent(){
    return this.bookReaderApi.getEbookContent(this._ebookPath, this.pageNum, 1).pipe(
      tap(response => {
        this.ebookContent = this.sanitizer.bypassSecurityTrustHtml(response)
      })
    )
  }

  showBookSizeSettings(){
    this.isBookSizeSettingsShown = !this.isBookSizeSettingsShown
  }

  changeTextSize(change: -1 | 1){
    this.bookReaderService.setSize(change)
  }

  jumpToPage(){
    this.pageNum = this.pageSelection
    this.updatePageNumberInParams(this.pageNum)
    this.getEbookContent().subscribe()
    this.jumpToPageModal.hide()
  }

  updatePageNumberInParams(pageNumber: number){
    this.router.navigate([], {
      queryParams: { page: pageNumber }
    })
  }

  getZoom(){
    return {zoom: `${this.zoom}`};
  }
}
