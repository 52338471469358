import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from "@angular/router";
import { filter, Subscription, switchMap } from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { ProfileService } from 'src/app/auth_profile/services/teacher/profile.service';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { ThemeService } from 'src/app/services/theme.service';
import {LangService} from "../../services/lang.service";
import { OffcanvasMenuService } from 'src/app/services/offcanvas-menu.service';

@Component({
    selector: 'app-unaffiliated-dashboard-layout',
    templateUrl: './unaffiliated-dashboard-layout.component.html',
    styleUrls: ['./unaffiliated-dashboard-layout.component.scss'],
    standalone: false
})
export class UnaffiliatedDashboardLayoutComponent implements OnInit, OnDestroy {
  activePath: string;
  activeSegments: string[];
  studentFullName: string;
  theme: string;
  profilePhoto;

  menuType: string
  offcanvasMenuSubscription: Subscription;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthorizationServiceProvider,
    public themeService: ThemeService,
    public provider: NativeServiceApiProvider,
    private langService: LangService,
    private offcanvasMenuService: OffcanvasMenuService
  ) {
    router.events.pipe(
      filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart ||
                   e instanceof NavigationEnd ||
                   e instanceof NavigationCancel ||
                   e instanceof NavigationError)
      ).subscribe((e: RouterEvent) => {
        this.activePath = e['urlAfterRedirects'];
        this.activePath
          ? (this.activeSegments = this.activePath.split('/'))
          : '';
      });

    this.langService.initialize()
  }

  /**
   * if native supports the casa profile use profile to get student name and school
   * @private
   */
  private loadProfileData() {
    this.profileService.getSelfPerson().subscribe((self) => {
      this.studentFullName = PersonUtils.getPersonName(self.details);
      this.profilePhoto = self?.details.photo?.publicAccessUrl || null;
    });
  }

  /**
   * if native doesn't support casa just load in an old way
   * @private
   */
  private loadAppData() {
    this.authService
      .getAuthDetailsService()
      .pipe(switchMap((api) => api.getUserName()))
      .subscribe((userName) => {
        this.studentFullName = userName;
      });
  }

  ngOnInit(): void {
    this.provider.casa().subscribe((api) => {
      if (api.isImplemented()) {
        this.loadProfileData();
      } else {
        this.loadAppData();
      }
    });
    this.themeService.getTheme().subscribe((theme) => (this.theme = theme));

    this.offcanvasMenuSubscription =  this.offcanvasMenuService.getCurrentMenu()
      .subscribe(menuType => this.menuType = menuType)
  }

  ngOnDestroy(){
    if(this.offcanvasMenuSubscription) this.offcanvasMenuSubscription.unsubscribe()
  }

  getStudentName() {
    return this.studentFullName;
  }

  onLogout() {
    this.authService
      .getCommonRoutines()
      .subscribe((service) => service.logout());
  }

  getAvatar() {
    return {
      background: this.profilePhoto ? `url('${this.profilePhoto}')` : null,
    };
  }
}
