<div class="workspace bg-radial">
  <top-bar class="d-none d-md-block">
    <content class="pt-0 pt-sm-2 mb-md-4 mx-2 mx-sm-4 justify-content-start justify-content-md-center">
      <button
        (click)="endSession()"
        class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        type="button"
      >
        <app-icon class="icon-sm" name="arrow-back"></app-icon>
        <span class="d-none d-lg-block ms-3">
          {{ "NAVIGATION.EXERCISE_SET" | translate }}
        </span>
      </button>
      <div class="d-md-none d-flex flex-column">
        <span>{{ getExerciseType() }}</span>
        <span>{{ getChapter() }}, {{ getExerciseName() }}</span>
      </div>
      <div class="d-none d-md-block text-center">
        <h4>{{ getExerciseType() }}</h4>
        <span class="d-md-none text-muted">
          {{ getChapter() }}, {{ getExerciseName() }}
        </span>
      </div>
    </content>
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a (click)="onFinish()" [routerLink]="getExerciseRouterLink()">
              {{ "NAVIGATION.EXERCISES" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="onFinish()" [routerLink]="getExerciseRouterLink()">{{ getExerciseSet() }}</a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="onFinish()" [routerLink]="getChapterRouterLink()">{{
                getChapter()
              }}</a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="onFinish()" routerLink="../../../">
              {{ getExamName() }}
            </a>
          </li>
          <li aria-current="page" class="breadcrumb-item active">
            {{ getExamPartName() }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="d-md-none">
    <div class="exercise-navbar d-flex mx-3 mx-sm-4 justify-content-between align-items-center">
      <button
        (click)="endSession()"
        *ngIf="!isSummaryDisplayed()"
        class="btn btn-outline-secondary rounded-pill btn-back-size"
        type="button"
      >
        <app-icon name="arrow-back"></app-icon>
      </button>
      <div class="d-flex flex-column">
        <span>
          {{ getChapter() }} {{ (isSampleExam() ? 'EXAM.START.SAMPLE.TITLE' : 'EXAM.START.TITLE') | translate }}
        </span>
        <span>
          Part {{ getCurrentPartNr() }}: {{ getExamPartName() }}
        </span>
      </div>
      <button
        aria-controls="offcanvasExerciseOptions"
        class="d-md-none border-0 bg-transparent px-0"
        data-bs-target="#offcanvasExerciseOptions"
        data-bs-toggle="offcanvas"
        type="button"
      >
        <app-icon name="dots-options"></app-icon>
      </button>

    </div>
    <p *ngIf="timeLimit >= 0" class="text-primary text-center mb-0"
      >{{ timeLimit | date: 'mm:ss' }}</p>
  </div>
  <div class="mt-4 mx-4 d-md-none">
    <app-progress-bar *ngIf="!isSummaryDisplayed()">
    </app-progress-bar>
  </div>
  <div class="container mt-3 mt-sm-4 mb-5 px-5">
    <div *ngIf="!isFinished" class="question-nav row mb-md-4 pb-2">
      <div *ngIf="!isSummaryDisplayed()" class="col d-none d-md-block my-auto">
        <app-progress-bar *ngIf="!isSummaryDisplayed()">
        </app-progress-bar>
      </div>
      <div class="btn-nav col-auto d-flex ms-auto">
        <p *ngIf="timeLimit >= 0"
           class="d-none d-md-block text-primary text-center my-auto">{{ timeLimit | date: 'mm:ss' }}</p>
        <button
          (click)="getHint()"
          *ngIf="isHintShown()"
          class="btn btn-round px-2 ms-3"
          type="button"
        >
          <app-icon name="help"></app-icon>
        </button>
        <button
          aria-controls="offcanvasExerciseOptions"
          class="d-none d-md-block border-0 bg-transparent px-0"
          data-bs-target="#offcanvasExerciseOptions"
          data-bs-toggle="offcanvas"
          type="button"
        >
          <app-icon name="dots-options"></app-icon>
        </button>
      </div>
    </div>
    <div class="mb-5">
      <p *ngIf="question?.question?.definition?.instructions" class="my-2 my-md-3 text-center text-muted">
        {{ question.question.definition.instructions }}
      </p>
      <ng-template loadQuestion></ng-template>
    </div>

    <div *ngIf="!isFinished" class="navbar-nav bottom-bar-padding">
      <div class="nav-item my-auto">
        <app-icon
          (click)="goToPrevious()"
          [ngClass]="{ 'icon-filled-gray pe-none' : +questionNb == 1 || isAudio() }"
          class="md-icon"
          name="arrow-back"
        ></app-icon>
      </div>
      <div
        *ngFor="let questionRequest of questionRequests"
        class="nav-item my-auto"
      >
        <div class="position-relative">
          <app-icon
            (click)="onShortcutClick(questionRequest?.text);
                      questionRequest?.text == 'speaker' ? isVolumeRangeShown = !isVolumeRangeShown : ''"
            *ngIf="questionRequest?.assetUrl"
            [class]="(questionRequest?.assetUrl === 'mic' || questionRequest?.assetUrl === 'play-arrow-disabled') ? '' : 'icon-filled-blue' "
            [ngClass]="{ 'icon-pulse' : getAnimation(questionRequest) === 'pulsating'}"
            [name]="questionRequest?.assetUrl"
            class="icon-sm"
          ></app-icon>
          <ng-container *ngIf="questionRequest?.text == 'speaker' && isVolumeRangeShown">
            <input (input)="onShortcutClick(questionRequest?.text + ':' + $event.target['value']); setRangeStyle($event.target['value'])" [value]="getQaRequestValue(questionRequest)" class="form-range speaker-range"
                   id="volumeRange"
                   type="range"
            >
          </ng-container>
        </div>
      </div>
      <div *ngIf="!questionRequests" class="nav-item pe-none">
      </div>
      <div class="nav-item my-auto justify-content-center">
        <app-icon
          *ngIf="isBtnBlocked()"
          class="icon-filled-gray tooltip md-icon"
          id="buttonNext"
          name="arrow-next"
        >
        <span class="tooltip-text">
          {{ getTooltipInfo() | translate }}
        </span>
        </app-icon>

        <app-icon
          (click)="onCheck()"
          *ngIf="!isBtnBlocked()"
          class="icon-stroke-blue md-icon"
          name="arrow-next"
        >
        </app-icon>
      </div>
    </div>
  </div>
</div>

<div
  aria-labelledby="offcanvasExerciseOptionsLabel"
  class="offcanvas-exercises offcanvas offcanvas-menu offcanvas-end"
  id="offcanvasExerciseOptions"
  tabindex="-1"
>
  <div class="offcanvas-body p-0 pt-4 pt-sm-0 d-flex">
    <app-exercises-options class="w-100">
      <content>
        <button
          (click)="examModal.show()"
          class="btn btn-primary rounded-pill"
          data-bs-dismiss="offcanvas"
          type="button"
        >
          {{ (isExamFinished() ? 'BUTTON.FINISH_EXAM' : 'BUTTON.GO_TO_NEXT_PART') | translate }}
        </button>
      </content>
    </app-exercises-options>
  </div>
</div>

<app-modal #examModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5>{{
          (isExamFinished() ?
            "EXAM.EXAM_MODAL.FINISHED_TITLE"
            : "EXAM.EXAM_MODAL.UNFINISHED_TITLE") | translate
        }}</h5>
    </div>
    <div class="modal-body">
      <p>
        <span *ngIf="timeLimit">
           {{ 'EXAM.EXAM_MODAL.TIME_LIMIT_INFO_1' | translate }}
          <span class="text-primary">{{ timeLimit | date: 'mm:ss' }}</span>
          {{ 'EXAM.EXAM_MODAL.TIME_LIMIT_INFO_2' | translate }}
        </span>
        <span>
          {{
            (isExamFinished() ? 'EXAM.EXAM_MODAL.FINISHED_INFO'
              : 'EXAM.EXAM_MODAL.UNFINISHED_INFO') | translate
          }}
        </span>
      </p>
    </div>
    <div class="modal-footer border-0">
      <button
        (click)="examModal.hide()"
        class="btn btn-primary rounded-pill"
        type="button"
      >
        {{ "BUTTON.CANCEL" | translate }}
      </button>
      <button
        (click)="goToNextPart()"
        class="btn btn-secondary rounded-pill"
        type="button"
      >
        {{ (isExamFinished() ? 'BUTTON.FINISH_EXAM' : 'BUTTON.GO_TO_NEXT_PART') | translate }}
      </button>
    </div>
  </div>
</app-modal>
<app-modal #timeLimitModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5>{{ 'EXAM.TIME_LIMIT_MODAL.TITLE' | translate }}</h5>
    </div>
    <div class="modal-body">
      <p *ngIf="isExamFinished()">
        {{ 'EXAM.TIME_LIMIT_MODAL.FINISHED_INFO' | translate }}
      </p>
      <p *ngIf="!isExamFinished()">
        {{
          'EXAM.TIME_LIMIT_MODAL.UNFINISHED_INFO' | translate: {
            partNr: getCurrentPartNr() + 1,
            sec: redirectCountdown
          }
        }}
      </p>
    </div>
    <div class="modal-footer border-0">
      <button
        (click)="goToNextPart()"
        class="btn btn-primary rounded-pill"
        type="button"
      >
        {{ (isExamFinished() ? 'BUTTON.SEE_RESULTS' : 'BUTTON.START_NOW') | translate }}
      </button>
    </div>
  </div>
</app-modal>
<app-modal #dictTimeLimitModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5>{{ 'EXAM.DICT_TIME_LIMIT_MODAL.TITLE' | translate }}</h5>
    </div>
    <div class="modal-body">
      <p *ngIf="isLastDictation()">
        {{
          'EXAM.DICT_TIME_LIMIT_MODAL.FINISHED_INFO' | translate: {
            partNr: getCurrentPartNr() + 1,
            sec: redirectCountdown
          }
        }}
      </p>
      <p *ngIf="!isLastDictation()">
        {{
          'EXAM.DICT_TIME_LIMIT_MODAL.UNFINISHED_INFO' | translate: {
            dictNr: +questionNb,
            nbOfDicts: session?.currentPart.questions.length,
            sec: redirectCountdown
          }
        }}
      </p>
    </div>
    <div class="modal-footer border-0">
      <button
        (click)="(isLastDictation() ? goToNextPart() : onCheck()); dictTimeLimitModal.hide()"
        class="btn btn-primary rounded-pill"
        type="button"
      >
        {{ (isLastDictation() ? 'BUTTON.GO_TO_NEXT_PART' : 'BUTTON.START_NOW') | translate }}
      </button>
    </div>
  </div>
</app-modal>
<app-modal #lockedExamPartSessionModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5>{{ 'EXAM.LOCKED_PART_MODAL.TITLE' | translate }}</h5>
    </div>
    <div class="modal-body">
      <p>
        {{ 'EXAM.LOCKED_PART_MODAL.INFO' | translate }}
      </p>
    </div>
    <div class="modal-footer border-0">
      <button
        (click)="goBackFromExam()"
        class="btn btn-primary rounded-pill"
        type="button"
      >
        {{ 'BUTTON.GO_BACK' | translate }}
      </button>
    </div>
  </div>
</app-modal>
