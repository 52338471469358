crp {
  display: none;
}

.headwords-like {
  background-color: #b2c3ff;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
  font-size: .9rem;
}

table.headwords-like td {
  padding: 1px 4px;
}

.bracket-left, .bracket-right
{
  position: relative;
  height:90%;
  padding:10px;
}

.bracket-left:after
{
  content: " ";
  position: absolute;
  border-radius: 0 4px 4px 0px;
  border-right:2px solid #666;
  border-top:2px solid #666;
  border-bottom:2px solid #666;
  padding:5px;
  top:-10px;
  bottom:-10px;
  right: -5px;
}

.bracket-right:before {
  content: " ";
  position: absolute;
  border-radius:4px 0 0 4px ;
  border-left:2px solid #666;
  border-top:2px solid #666;
  border-bottom:2px solid #666;
  padding:5px;
  top:-10px;
  bottom:-10px;
  left: -5px;
}

.book-container {
  font-size: 0.7rem;
}

.book-container crp {
  font-size: 0px;
}

page {
  display: block;
  height: 100%;
  background-color: #fff;
  padding: 10px 40px 80px 40px;
  border-radius: 10px;
  overflow: scroll;
}

.student-working-area page {
  margin-bottom: 20px;
}

lessonnumber {
  display: block;
  color: #39598a;
  font-size: 1.5rem;
  font-weight: 900;
  border-bottom: solid 5px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.book-container img {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

app-book-renderer .book-container img {
  width: auto;
  max-width: unset;
  height: 100px;
}

.book-container .grande {
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 1;
}

.book-container icon {
  display: block;
  font-size: 0.7rem;
  font-weight: 400;
  color: #363e54;
  text-align: left;
  margin: 10px 10px;
  border-bottom: solid 1px;
  line-height: .7rem;
}

.book-container chart.chart-selected icon {
  color: red;
}

.book-container icon[icon-name="chart"]::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f03e"; font-size: 1.8rem; margin-right: .2rem;
}

hwinst, preqainst, postqainst {
  display: inline-block;
  margin: 2px 0px;
}

expl {
  display: none;
}

hwline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  font-size: 1rem;
  line-height: 1;
  margin: 4px 0px;
  background: #b2c3ff;
  padding: 2px 0px;
}

hw {
  flex-basis: auto;
  margin: 4px 10px;
  color: black;
}

muted {
  color: #6c7884;
}

hw subhw {
  display: block;
  width: 100%;
  text-align: center;
  border-top: 2px dashed;
}

subhwline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  font-size: 1rem;
  line-height: 1;
  background: #d4ddfb;
  padding: 2px 0px;
  margin: -3px 0px 4px 0px;
}

idiom {
  background: #e0e7ff;
  display: block;
  padding: 4px 8px;
  font-size: 1rem;
  margin: 4px 0px;
}

idiomtitle{
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  color: #001933;
  margin-bottom: 4px;
}

idiom-cont{
  display: block;
  font-size: 1.1rem;
  margin-bottom: 4px;
}

idiom-example{
  display: block;
}

subhwline subhw {
  flex-basis: auto;
  margin: 4px 10px;
}

qa-block {
  display: block;
  background: #E8E9EB;
  padding: 8px 8px;
  border-radius: 4px;
  margin: 16px 2px 12px 2px;
}
qa {
  display: block;
}

que {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #1C2459;
}

ans {
  color: #601122;
  font-size: 0.9rem;
  text-align: right;
  display: block;
}

callout {
  display: none;
}

error {
  display: block;
  text-align: center;
  padding: 100px 15px;
  font-size: 2rem;
  color: #f70000;
}

notification {
  display: block;
  padding: 100px 15px;
  text-align: center;
  font-size: 2rem;
  color: #1d2e54;
}

page-number {
  display: block;
  color: #ea0000;
  font-weight: 800;
  text-decoration: underline;
  margin: 5px;
  text-align: right;
}

section-title {
  display: block;
  font-size: 1.5rem;
  border-bottom: dotted #aaa 2px;
  margin-bottom: .5rem;
  color: #203267;
}


exam-section, exercise-section, general-section {
  font-size: .9rem;
  display: block;
  background: #c3cbd6;
  padding: .5rem .5rem 1rem 0.5rem;
}

exam-section hwline, exercise-section hwline, general-section hwline {
  background: none;
  font-size: 1.2rem;
  line-height: 1.2;
  color: #203267;
  font-weight: 600;
}

exam-section > section-title, exercise-section > section-title {
  display: block;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 700;
  border-bottom: none;
}

question-list {
  display: block;
}

exam-question-list[columns] {
  column-count: 2;
}

note {
  color: #7a7a7a;
  font-style: italic;
}

er {
  display: flex;
  padding: .2rem 0rem .2rem 0rem;
}

er.regular {
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

eo {
  display: block;
  order: 1;
  flex-basis: 2rem;
  flex-shrink: 0;
}

er note {
  order: 2;
  flex-basis: 5rem;
  flex-grow: 0;
  flex-shrink: 0;
}

er ea note {
  order: unset;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
}

eq {
  display: block;
  order: 3;
  flex-grow: 2;
  flex-basis: 20rem;
}


ea {
  display: block;
  order: 2;
  flex-basis: 5rem;
  flex-grow: 0;
  flex-shrink: 0;
}

er.regular ea {
  order: 4;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 0.2rem 0.2rem 0.2rem 3rem;
}

er ea var {
  font-style: normal;
  display: block;
  padding-right: 1.5rem;
  flex: 1 0 0%;
  min-width: 10rem;
}

er ea var[correct="true"] {
  text-decoration: underline;
}

eph {
  display: inline-block;
  width: 5rem;
  border-bottom: solid black 0.1rem;
}

exercise-section er {
    flex-wrap: wrap;
}

exercise-section eo {
  order: 1;
}

exercise-section eq {
  order: 2;
  max-width: calc(100% - 2rem);
}

exercise-section ea {
  order: 3;
  width: 100%;
  flex-grow: 5;
  flex-basis: auto;
  text-align: right;
  margin-bottom: 5px;
}

emph {
  font-weight: 600;
  color: #203267;
}

general-section td {
  padding-left: 5px;
  padding-right: 5px;
}

dottedbox {
  padding: 12px 12px;
  margin: 10px 8px;
  border: dashed 1px;
  display: block;
}

extra {
  display: block;
  padding: .5rem 0;
  color: #203267;
}

extra[channel='occ'] {
  background: #e0e7ff;
  padding: .5rem .5rem;
  color: black;
}

book { 
  display: block;
  height: 100%;
}

book .bg-light {
  background-color: #DFE1EC;
  color: #002e5f;
}

book .display-1 {
  font-size: 2rem;
  font-weight: 200;
  line-height: .9;
}

book .display-2 {
  font-size: 1.5rem;
  font-weight: 200;
  line-height: .9;
}

book .mt-3 {
  margin-top: 3rem;
}

book .mb-1 {
  margin-bottom: 0.5rem;
}

book .mb-2 {
  margin-bottom: 1rem;
}

book .mb-3 {
  margin-bottom: 3rem;
}

book .mt-5 {
  margin-top: 5rem;
}

book .my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

book .py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}


book small, .small {
  font-size: 80%;
}

book strong .strong {
  font-weight: 600;
}

book .text-center {
  text-align: center;
}

book .text-uppercase {
  text-transform: uppercase;
}

book .float-left {
  float: left;
}

book .w-50 {
  width: 50%;
}

book img.w-100 {
  display: block;
  width: 100%;
  margin: auto;
}

book .max-sm {
  max-width: 480px;
}

book .max-md {
  max-width: 960px;
}

book .table-responsive {
  width: 100%;
  overflow-x: auto;
}

book table {
  border-collapse: collapse;
}

book .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

book .table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

book .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

book .table-active, .table-active>td, .table-active>th {
  background-color: rgba(0,0,0,.075);
}


.book-container {
  font-size: 0.7rem;
  color: black;
  width: inherit;
  border-radius: 10px;
  overflow: hidden;
}

.control-bar {
  position: fixed;
  display: flex;
  background: var(--bg-1)!important;
  flex-direction: row;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 0.5rem 0;
  justify-content: space-between;

  .nav-item {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    border-radius: 3.5rem 0 0 3.5rem;

    app-icon {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      right:0;
    }
  }
}

.font-size-menu {
  position: absolute;
  bottom: 42px;
  left: -9px;
  background-color: var(--bg-1);
  border: solid var(--bg-2);
}

.jump-to-page {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: var(--input);
  border: none;
  padding: 1.5rem;
}

.page-input{
  background-color: white!important;
  color: black!important;
}

@media screen and (min-width: 576px) {
  .control-bar {
    padding: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .control-bar {
    position: absolute;
    border-radius: 3rem;
    padding: 0;
    width: fit-content;
    margin: 0 auto;
    min-width: 20rem;
    bottom: 2rem;
  }
}